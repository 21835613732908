// import external dependencies
import jquery from 'jquery';
global.$ = global.jQuery = jquery;

// import 'bootstrap/js/dist/modal';

import 'bootstrap/js/dist/offcanvas';
import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/tab';

import './glide';
import './swiper';
import './form';