import Swiper from 'swiper/bundle';

var swiper = null;

function initSwiper() {
  var windowWidth = window.innerWidth;
  if (swiper !== null) {
    swiper.destroy();
  }
  swiper = new Swiper(".swiper-image-car-thumbnail", {
    loop: true,
    spaceBetween: 10,
    slidesPerView: 4,
    freeMode: true,
    watchSlidesProgress: true,
    direction: windowWidth > 992 ? "vertical" : "horizontal",
  });

  new Swiper(".swiper-image-car", {
    loop: true,
    effect: "fade",
    spaceBetween: 10,
    thumbs: {
      swiper: swiper,
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
  });
}

initSwiper();
window.addEventListener("resize", initSwiper);
