// restrict number phone form
$(document).on("input", 'input[type="tel"]', function () {
  $(this).val(
    $(this)
      .val()
      .replace(/[^0-9]/g, "")
  );
});

// restrict number zip form
$(document).on("input", '.zip input[type="text"]', function () {
  $(this).val(
    $(this)
      .val()
      .replace(/[^0-9]/g, "")
  );
});
