import '@glidejs/glide/dist/glide.min';

import Glide from '@glidejs/glide';

window.addEventListener('load', () => {
  if (document.querySelector('.glide')) {
    const glide = new Glide('.glide', {
      type: 'carousel',
      focusAt: 'center',
      perView: 1.1,
      startAt: 1,

      breakpoints: {
        767: {
          perView: 1.3,
        },
      },
    });
    glide.mount();
  }
});
